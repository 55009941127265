import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ title, description, image, article, author }) => {
   const { pathname } = useLocation();
   const { site } = useStaticQuery(query);

   const {
      defaultSiteName,
      defaultTitle,
      titleTemplate,
      defaultDescription,
      siteUrl,
      defaultAuthor,
   } = site.siteMetadata;

   const seo = {
      siteName: defaultSiteName,
      title: title || defaultTitle,
      description: description || defaultDescription,
      image: `${siteUrl}${image}`,
      url: `${siteUrl}${pathname}`,
      author: author || defaultAuthor,
   };

   return (
      <Helmet title={seo.title} titleTemplate={titleTemplate}>
         <meta name="description" content={seo.description} />
         {image && <meta name="image" content={seo.image} />}
         <meta name="author" content={seo.author} />
         <meta property="og:site_name" content={seo.siteName} />
         <meta property="og:locale" content="en_US" />

         {seo.url && <meta property="og:url" content={seo.url} />}

         {(article ? true : null) && (
            <meta property="og:type" content="article" />
         )}
         {(!article ? true : null) && (
            <meta property="og:type" content="website" />
         )}

         {seo.title && <meta property="og:title" content={seo.title} />}

         {seo.description && (
            <meta property="og:description" content={seo.description} />
         )}

         {image && <meta property="og:image" content={seo.image} />}
         {image && <meta property="og:image:width" content="1200" />}
         {image && <meta property="og:image:height" content="630" />}
         <script type="application/ld+json">
            {`
          {
            "@context": "https://schema.org",
            "@type": "Website",
            "name": "Nearshore.pt",
            "url": "https://nearshore.pt/",
            "description": "We provide IT-staffing solutions tailored to your needs at prices that make sense. Curious how you can maximize your IT budget? Try our IT staffing calculator, Hirewise.",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://nearshore.pt/"
            },
            "publisher": {
              "@type": "Organization",
              "name": "Caixa Mágica Software",
              "logo": {
                "@type": "ImageObject",
                "url": "https://caixamagica.pt/wp-content/uploads/2023/08/CMS_Horizontal_Simple_Black.svg"
              }
            }
          }
        `}
         </script>
      </Helmet>
   );
};

export default SEO;

SEO.propTypes = {
   title: PropTypes.string,
   description: PropTypes.string,
   image: PropTypes.string,
   article: PropTypes.bool,
};

SEO.defaultProps = {
   title: null,
   description: null,
   image: null,
   article: false,
};

const query = graphql`
   query SEO {
      site {
         siteMetadata {
            defaultSiteName: siteName
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            siteUrl: url
            defaultAuthor: author
         }
      }
   }
`;
